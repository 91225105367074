@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "LORE";
  src: url("./assets/LORE/LORE-Alternates-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "RUBIK";
  src: url("./assets/RUBIK/Rubik-Medium.woff2") format("woff2");
}

.twodrinks {
  width: 600px;
  height: 600px;
}

.threerinks {
  width: 600px;
  height: 600px;
  padding-top: 3px;
}

.homebg {
  width: 620px;
  height: 803px;
  padding-bottom: 20px;
}

.founderstwo {
  width: 620px;
  height: 803px;
  padding-bottom: 20px;
}

.grouppic {
  width: 900px;
  height: 700px;
}

.mission {
  width: 420px;
  height: 700px;
}

.founders {
  width: 500px;
  height: 775px;
  padding-bottom: 50px;
}

.about {
  width: 500px;
  height: 775px;
  padding-bottom: 50px;
}

.terrazzo {
  width: 1320px;
  height: 900px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.footer {
  width: auto;
  height: 110px;
}

@media only screen and (max-width: 480px) {
  .twodrinks {
    width: 450px;
    height: 400px;
    padding-bottom: 0;
  }

  .threerinks {
    width: 450px;
    height: 400px;
    padding-top: 0;
  }

  .homebg {
    width: 620px;
    height: auto;
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .founderstwo {
    width: 620px;
    height: auto;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .grouppic {
    width: auto;
    height: 320px;
  }

  .mission {
    width: 500px;
    height: 300px;
  }

  .founders {
    width: 500px;
    height: 550px;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
  }

  .about {
    width: 500px;
    height: 500px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .terrazzo {
    width: 600px;
    height: 1165px;
    padding-top: 0px;
  }
}

